<template>
  <WeContainer card="" seo-bar="" v-if="ready">
    <div>
      <Navigation
        v-on:save-form="submitForm"
        v-bind:submit="submit"
        v-bind:errors="errors"
      />
    </div>
    <div class="tab-content">
      <div class="tab-pane fade show active">
        <div class="row align-items-stretch mb-xl-3">
          <div class="col-12 mb-3 col-xl mb-xl-0">
            <div class="card h-100">
              <div class="card-body">
                <!-- Member Type Name -->
                <div class="form-group mb-3">
                  <WeInput
                    label="Üye Tipi Adı"
                    name="member_type_name"
                    v-bind:required="true"
                    v-model="memberTypeName"
                    v-bind:error="$v.memberTypeName.$error"
                    v-on:keyup-enter="submitForm()"
                  />
                </div>
                <!-- ./Member Type Name -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </WeContainer>
  <WeLoading v-else />
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
const Navigation = () => import("./views/Navigation/Index");

export default {
  name: "Detail",
  data() {
    return {
      ready: false,
      submit: false,
      errors: [],
    };
  },
  components: {
    Navigation,
  },
  validations: {
    memberTypeName: {
      required,
    },
  },
  methods: {
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    ...mapActions("membertype", ["load", "create", "update", "getById"]),
    validateForm() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });

        return false;
      } else {
        return true;
      }
    },
    submitForm() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;

      if (this.$route.params.id) {
        this.updateMemberType();
      } else {
        this.addMemberType();
      }
    },
    addMemberType() {
      this.create({
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showAlert(result, this.redirectToList);
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    updateMemberType() {
      this.update({
        id: this.$route.params.id,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showAlert(result, this.redirectToList);
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    getMemberTypeById() {
      if (this.$route.params.id) {
        this.getById({
          id: this.$route.params.id,
          onSuccess: (result) => {
            this.ready = true;
          },
        });
      } else {
        this.ready = true;
      }
    },
    redirectToList() {
      this.$router.push("/member-types");
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
  },
  computed: {
    ...mapState(["membertype", "shared"]),
    getTitle() {
      return this.$route.params.id ? "ÜYE TİPİ DÜZENLE" : "ÜYE TİPİ EKLE";
    },
    checkPermission() {
      return permission.check("memberType", "u");
    },
    memberTypeName: {
      get() {
        return this.membertype.new.name;
      },
      set(value) {
        this.membertype.new.name = value;
      },
    },
  },
  mounted() {
    this.load();
    this.getMemberTypeById();
  },
};
</script>
